import * as moment from 'moment'

moment.locale('pt-br')

export default {
  getQueryParams(params) {
    // eslint-disable-next-line prefer-template
    return Object.keys(params).map(key => key + '=' + params[key]).join('&')
  },
  getByLetter(letter) {
    switch (letter) {
      case 'M':
        return 'Masculino'
      case 'F':
        return 'Feminino'
      default:
        return 'N/A'
    }
  },
  getClassColorPill(type) {
    switch (type) {
      case 'Aplicação do Jogo':
        return 'pill-testecog'
      case 'Jogo Teste':
        return 'pill-jogoteste'
      case 'Consulta Inicial':
        return 'pill-cons-ini'
      case 'Consulta Final':
        return 'pill-cons-fin'
      case 'Testes Cognitivos':
        return 'pill-testcog'
      default:
        return type
    }
  },
  getAgeByBirthday(birthday) {
    return moment().diff(birthday, 'years', false)
  },
  getFromNowByDate(date) {
    return moment(date).fromNow()
  },
  getExtenseDate(date) {
    return moment(date).fromNow()
  },
  getAbreviate(firstName, lastName) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  },
  getFullNameByPatient(patient) {
    return `${patient.physicalPerson.firstName} ${patient.physicalPerson.lastName}`
  },
  getAbreviateByPatient(patient) {
    if(!patient) return ''
    return this.getAbreviate(patient.physicalPerson.firstName, patient.physicalPerson.lastName)
  },
  isNullOrEmpty(obj) {
    console.log(typeof obj)
    if (Array.isArray(obj)) {
      return obj.length === 0
    }
    if (typeof obj === 'object') {
      return Object.keys(obj).every(value => this.isNullOrEmpty(obj[value]))
    }
    if (!obj) {
      return true
    }
  },
  getTitleRole(role) {
    console.log(role)
    if (role === 'admin') return 'Professor'
    return ''
  }

}
